import useAuth, { User } from '@/auth'
import useMemberRole from '@/auth/useMemberRole'
import envStore from '@/env'
import { ISimpleModelHistoryState } from '@/routes/history'
import { concat, keys } from 'ramda'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

const useSimpleMode = () => {
  const { state: locationState } = useLocation<
    ISimpleModelHistoryState | undefined
  >()

  const { OrganizationManagerRoleId } = useMemberRole()

  const history = useHistory()

  const { user } = useAuth()

  const isUsedSimpleModeByUser = React.useCallback((user?: User) => {
    if (!envStore.simpleModeSupported) {
      return false
    }
    if (!user) {
      return false
    }
    return user.memberRoleId >= OrganizationManagerRoleId
  }, [])

  const isUsedSimpleMode = React.useMemo<boolean>(
    () =>
      envStore.simpleModeSupported
        ? user?.memberRoleId >= OrganizationManagerRoleId
        : false,
    [user?.memberRoleId]
  )

  const state = React.useMemo<{
    isAddedMode?: boolean
    actSimpleFillinData: Record<string, any> | undefined
    hiddenValues: string[]
    specialPayload?: ISimpleModelHistoryState['specialPayload']
  }>(
    () => ({
      isAddedMode: locationState?.isAddedMode,
      actSimpleFillinData: locationState?.actSimpleFillinData as
        | Record<string, any>
        | undefined,
      hiddenValues: concat(keys(locationState?.actSimpleFillinData), [
        'originAmountRow',
        'outerLink',
      ]),
      specialPayload: locationState?.specialPayload,
    }),
    [
      locationState?.actSimpleFillinData,
      locationState?.isAddedMode,
      locationState?.specialPayload,
    ]
  )

  const clearStateIfUsed = React.useCallback(() => {
    history.replace({})
  }, [])

  return {
    isUsedSimpleMode,
    state,
    clearStateIfUsed,
    isUsedSimpleModeByUser,
  }
}

export default useSimpleMode
