import { SVGProps } from 'react'

const Report = (props: SVGProps<any>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M11.875 6.875C11.875 6.52982 12.1548 6.25 12.5 6.25H15.625C15.9702 6.25 16.25 6.52982 16.25 6.875C16.25 7.22018 15.9702 7.5 15.625 7.5H12.5C12.1548 7.5 11.875 7.22018 11.875 6.875ZM11.875 10C11.875 9.65482 12.1548 9.375 12.5 9.375H15.625C15.9702 9.375 16.25 9.65482 16.25 10C16.25 10.3452 15.9702 10.625 15.625 10.625H12.5C12.1548 10.625 11.875 10.3452 11.875 10ZM11.875 13.125C11.875 12.7798 12.1548 12.5 12.5 12.5H15.625C15.9702 12.5 16.25 12.7798 16.25 13.125C16.25 13.4702 15.9702 13.75 15.625 13.75H12.5C12.1548 13.75 11.875 13.4702 11.875 13.125ZM3.75 6.875C3.75 6.52982 4.02982 6.25 4.375 6.25H7.5C7.84518 6.25 8.125 6.52982 8.125 6.875C8.125 7.22018 7.84518 7.5 7.5 7.5H4.375C4.02982 7.5 3.75 7.22018 3.75 6.875ZM3.75 10C3.75 9.65482 4.02982 9.375 4.375 9.375H7.5C7.84518 9.375 8.125 9.65482 8.125 10C8.125 10.3452 7.84518 10.625 7.5 10.625H4.375C4.02982 10.625 3.75 10.3452 3.75 10ZM3.75 13.125C3.75 12.7798 4.02982 12.5 4.375 12.5H7.5C7.84518 12.5 8.125 12.7798 8.125 13.125C8.125 13.4702 7.84518 13.75 7.5 13.75H4.375C4.02982 13.75 3.75 13.4702 3.75 13.125Z"
      fill="#878787"
    />
    <path
      d="M17.5 3.025H2.5C2.14196 3.025 1.79858 3.16723 1.54541 3.42041C1.29223 3.67358 1.15 4.01696 1.15 4.375V15.625C1.15 15.983 1.29223 16.3264 1.54541 16.5796C1.79858 16.8328 2.14196 16.975 2.5 16.975H17.5C17.858 16.975 18.2014 16.8328 18.4546 16.5796C18.7078 16.3264 18.85 15.983 18.85 15.625V4.375C18.85 4.01696 18.7078 3.67358 18.4546 3.42041C18.2014 3.16723 17.858 3.025 17.5 3.025ZM2.6 4.475H9.275V15.525H2.6V4.475ZM10.725 15.525V4.475H17.4V15.525H10.725Z"
      fill="#878787"
      stroke="#878787"
      stroke-width="0.2"
    />
  </svg>
)

export default Report
