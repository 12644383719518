import React from 'react'
import styled, { css } from 'styled-components'
import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import * as Icons from '@icons'
import type { ActionTypes } from '@/auth'
import useRouteAuth from '@/routes/useRouteAuth'
import { ACTIONS_ROUTES } from '@components/../lib/routes'
import { isNil } from 'ramda'

/**
 *  列出留給 theme v0 使用 for 相容
 *  color: outline
 */
export interface Props extends Omit<ButtonProps, 'icon' | 'type'> {
  children?: React.ReactNode
  circle?: boolean
  color?:
    | 'primary'
    | 'ghost'
    | 'warning'
    | 'outline'
    | 'danger'
    | 'secondary'
    | 'grey'
    | string
  icon?: string | React.ReactNode
  onClick?: (evt: React.MouseEvent<HTMLElement>) => any
  actionType?: ActionTypes
  /**
   * 使用對應活動類別的權限
   */
  authByActType?: number
  rounded?: boolean
  theme?: object
  iconPosition?: string
  type?:
    | 'default'
    | 'primary'
    | 'ghost'
    | 'dashed'
    | 'link'
    | 'text'
    | 'icon'
    | undefined
}

const StyledButton = styled(({ circle, rounded, ...props }) => (
  <Button {...props} />
))<Props>`
  &.ant-btn {
    border-radius: 4px;
    font-weight: 500;

    min-height: 32px;
    height: auto;

    .ant-btn-loading-icon {
      display: inline-flex;
    }

    .anticon {
      margin-right: 6px;
    }

    svg {
      width: 20px;
      height: 20px;
    }

    &.ant-btn-sm svg {
      width: 16px;
      height: 16px;
    }

    &.is--rounded {
      border-radius: 16px;
    }

    ${p =>
      p.color === 'primary' &&
      css`
        color: ${p => p.theme.btn.text};
        background: ${p => p.theme.btn.bg};
        border: 1px solid ${p => p.theme.btn.border};
        box-shadow: ${p => p.theme.btn.shadow};

        &:hover,
        &:focus,
        &:active {
          border: 1px solid ${p => p.theme.btn.bgHover};
          background: ${p => p.theme.btn.bgHover};
        }

        .anticon:hover {
          svg {
            fill: ${p => p.theme.btn.text};
          }
        }

       svg {
          width: 16px;
          height: 16px;

          g path:first-child {
            fill:${p => p.theme.btn.text};
            }
          }
        }
      `};

    ${p =>
      p.color === 'secondary' &&
      css`
        height: 24px;
        line-height: 1;
        padding-top: 3px;
        box-shadow: ${p => p.theme.btn.shadow};
        border-radius: ${p => p.theme.btn.secondary.borderRadius};
        color:  ${p => p.theme.btn.secondary.text};
        background:  ${p => p.theme.btn.secondary.bg};
        border: 1px solid ${p => p.theme.btn.secondary.border}; !important;

        &:hover,
        &:focus,
        &:active {
          border: 1px solid  ${p =>
            p.theme.btn.secondary.hoverBorder} !important;
          background:  ${p => p.theme.btn.secondary.hoverBg};
        }

        svg {
          width: 16px;
          height: 16px;

          g path:first-child {
            fill:${p => p.theme.btn.secondary.icon.color};
            }
          }
        }
     `};

    ${p =>
      p.color === 'ghost' &&
      css`
        color: ${p => p.theme.btn.ghost.text};
        background: transparent;
        border: 1px solid ${p => p.theme.btn.ghost.border};

        &:hover,
        &:focus,
        &:active {
          border: 1px solid ${p => p.theme.btn.ghost.border};
          background: ${p => p.theme.btn.ghost.hover};
        }

        .style--btn-icon {
          g path:first-child {
            fill: ${p => p.theme.btn.ghost.text};
          }
        }

        .style--btn-icon-import {
          g path[stroke] {
            stroke: ${p => p.theme.btn.ghost.text};
          }
        }
      `};

    ${p =>
      p.color === 'warning' &&
      css`
        color: #fff;
        background: ${p => p.theme.gamboge};
        border: 1px solid ${p => p.theme.gamboge};

        &:hover,
        &:focus,
        &:active {
          border: 1px solid ${p => p.theme.gamboge};
          background: ${p => p.theme.gamboge};
        }
      `};

    ${p =>
      p.color === 'outline' &&
      css`
        min-width: 104px;
        padding: 0 8px;
        color: ${p => p.theme.blue};
        border: 1.3px solid ${p => p.theme.btn.border};
        background: #fff;

        &:hover,
        &:focus,
        &:active {
          color: ${p => p.theme.blue};
          background: #fff;
          border: 1.3px solid ${p => p.theme.btn.border};
        }
      `};

    ${p =>
      p.color === 'danger' &&
      css`
        color: ${p => p.theme.btn.danger.text};
        border: 1.3px solid ${p => p.theme.btn.danger.border};
        background: transparent;

        &:hover,
        &:focus {
          color: ${p => p.theme.btn.danger.text};
          background: ${p => p.theme.btn.danger.hover};
          border: 1.3px solid ${p => p.theme.btn.danger.border};
        }
      `};

    ${p =>
      p.color === 'grey' &&
      css`
        line-height: 1;
        padding-top: 3px;
        border: 1px solid ${p => p.theme.darkGrey};
        box-shadow: ${p => p.theme.btn.shadow};
        border-radius: 4px;
        color: #000;
        background: ${p => p.theme.grey};

        &:hover,
        &:focus,
        &:active {
          border: 1px solid ${p => p.theme.darkGrey};
          background: ${p => p.theme.grey};
        }
      `};

    ${p =>
      p.circle &&
      css`
        border-radius: 16px;
      `};

    ${p =>
      p.type === 'link' &&
      css`
        min-width: unset !important;
        padding: 0 !important;
        background: transparent;
        border-color: transparent;
        color: ${p.theme.btn.linkColor};
        font-size: unset;

        > span {
          text-decoration: none;
        }

        &:hover {
          background: transparent;
          border-color: transparent;
          color: ${p.theme.btn.linkHover};
        }
      `};

    ${p =>
      p.type === 'icon' &&
      css`
        border: 0px;

        svg {
          width: 16px;
          height: 16px;
        }

        .anticon:hover {
          svg {
            fill: ${p => p.theme.btn.text};
          }
        }
      `};

    ${p =>
      p.size === 'small' &&
      css`
        height: 24px;
        line-height: 1.2;
        min-width: fit-content !important;
        padding: 2px 12px !important;
        min-height: fit-content !important;
      `}

    ${p =>
      p.rounded &&
      css`
        border-radius: 16px;
      `}

    ${p =>
      p.iconPosition === 'right' &&
      css`
        .anticon {
          order: 1;
          margin-left: 8px;
        }
      `}
  }

  &.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
    padding-left: 20px;
  }

  &.ant-btn[disabled] {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;

    svg > g path:nth-child(2) {
      transition: fill 0.4s ease;
      fill: darkgrey;
    }
  }

  .icon-add > g path:nth-child(2) {
    fill: white;
  }
`

export { StyledButton as Butotn }

const getActTypeAuthUrl = (actType?: number) => {
  if (isNil(actType)) {
    return undefined
  }
  return ACTIONS_ROUTES[actType] || undefined
}

export default function ButtonComponent({
  icon,
  children,
  loading,
  actionType,
  authByActType,
  onClick,
  ...props
}: Props) {
  const [, checkIsAuthenticated] = useRouteAuth()

  if (
    actionType &&
    !checkIsAuthenticated(actionType, getActTypeAuthUrl(authByActType))
  ) {
    return null
  }

  return (
    <StyledButton
      onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        onClick && onClick(e)
        e.currentTarget.blur()
      }}
      {...props}
      loading={loading}>
      {(() => {
        if (icon && !loading) {
          let Icon

          const isElement = React.isValidElement(icon)

          if (!isElement) {
            Icon = icon
          }

          if (typeof icon === 'string') {
            const nameCapitalized = icon.charAt(0).toUpperCase() + icon.slice(1)
            const iconName = 'Icon' + nameCapitalized
            Icon = (Icons as any)[iconName]
          }

          return (
            <div className="flex items-center">
              {isElement ? (
                icon
              ) : (
                <Icon className="c-icon mr-4 is--secondary" />
              )}
              {children}
            </div>
          )
        }

        return children
      })()}
    </StyledButton>
  )
}
