export const breakPoints = {
  mobile: 768,
}

export const StatusEnum = {
  DELETE: -1,
  INACTIVE: 0,
  ACTIVE: 1,
}

export const StatusName = {
  [StatusEnum.DELETE]: 'Delete',
  [StatusEnum.INACTIVE]: 'Disable',
  [StatusEnum.ACTIVE]: 'Enable',
}

export const ActivityStatusEnum = {
  NORMAL: 0,
  APPROVAL: 1,
  DELETE: 2,
  REJECT: 3,
  APPROVAL_SUCCESS: 4,
  DRAW_APPROVAL: 5,
  CANCEL_APPROVAL: 6,
}

export const ActivityStatusName: any = {
  /**
   *使用在 common.json
   */
  0: 'ActivityStatus.NORMAL',
  1: 'ActivityStatus.APPROVAL',
  2: 'ActivityStatus.DELETE',
  3: 'ActivityStatus.REJECT',
  4: 'ActivityStatus.APPROVAL_SUCCESS',
  5: 'ActivityStatus.DRAW_APPROVAL',
  6: 'ActivityStatus.CANCEL_APPROVAL',
}
// 簽核列表
export const ApprovalStatus = {
  ADD: 0,
  EDIT: 1,
  DELETE: 2,
}

export const AuditStatusEnum = {
  UNAUDIT: -1,
  ABNORMAL: 0,
  SUCCESS: 1,
}

export const AuditStatusName: any = {
  [-1]: 'AuditStatus.UNAUDIT',
  0: 'AuditStatus.ABNORMAL',
  1: 'AuditStatus.SUCCESS',
}

export const ReviewTypeEnum = {
  ADD: 0,
  EDIT: 1,
  DELETE: 2,
}

/**
 * 簽核動作
 */
export const AuditActionTypeEnum = {
  APPROVAL: 0,
  REJECT: 1,
  DRAW_APPROVAL: 2,
  CANCEL_APPROVAL: 3,
}

/**
 * 運輸類別
 */
export const TransCategoryTypeEnum = {
  LAND: 0,
  MRT: 1,
  AIR: 2,
}

/**
 * 運輸類別顯示文字
 */
export const TransCategoryTypeDisplayEnum: any = {
  0: 'TransCategoryType.LAND',
  1: 'TransCategoryType.MRT',
  2: 'TransCategoryType.AIR',
}

/**
 * 貨物運輸類別
 */
export const GoodsTranCategoryTypeEnum = {
  LAND: 0,
  MARITIME: 1,
  AIR: 2,
}

/**
 * 貨物運輸類別顯示文字
 */
export const GoodsTranCategoryTypeDisplayEnum: any = {
  0: 'GoodsTranCategoryType.LAND',
  1: 'GoodsTranCategoryType.MARITIME',
  2: 'GoodsTranCategoryType.AIR',
}
/**
 * 活動成案狀態 0:未成案;1:已成案;2:刪除;
 */
export const DocState: { [key: number]: any } = {
  0: 'CASE_PENDING',
  1: 'CASE_CLOSED',
  2: 'CASE_DELETE',
}
