import envStore from '@/env'
import { includes } from 'ramda'
import { isNil } from '@/utils/webHelper'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActTypeEnum } from '@/act'

/**
 * 欄位是否必填
 */
export const useRequiredFields = (atype?: number) => {
  const required = React.useMemo(() => {
    switch (atype) {
      case ActTypeEnum.StationaryCombustion:
        return {
          isRequiredEquiementInfo: Boolean(
            envStore.requiredFixedCombustionEquiementInfo
          ),
          isRequiredFirm: Boolean(envStore.requiredFixedCombustionFirm),
          isRequiredNtdAmount: Boolean(
            envStore.requiredFixedCombustionNtdAmount
          ),
          isRequiredOriginAmount: Boolean(
            envStore.requiredFixedCombustionOriginAmount
          ),
          isRequiredReference: Boolean(
            envStore.requiredFixedCombustionReference
          ),
        }
      case ActTypeEnum.MovableCombustion:
        return {
          isRequiredEquiementInfo: Boolean(
            envStore.requiredMobileCombustionEquiementInfo
          ),
          isRequiredFirm: Boolean(envStore.requiredMobileCombustionFirm),
          isRequiredNtdAmount: Boolean(
            envStore.requiredMobileCombustionNtdAmount
          ),
          isRequiredOriginAmount: Boolean(
            envStore.requiredMobileCombustionOriginAmount
          ),
          isRequiredReference: Boolean(
            envStore.requiredMobileCombustionReference
          ),
        }
      case ActTypeEnum.ProcessEmissions:
        return {
          isRequiredEquiementInfo: Boolean(
            envStore.requiredProcessEmissionEquiementInfo
          ),
          isRequiredFirm: Boolean(envStore.requiredProcessEmissionFirm),
          isRequiredNtdAmount: Boolean(
            envStore.requiredProcessEmissionNtdAmount
          ),
          isRequiredOriginAmount: Boolean(
            envStore.requiredProcessEmissionOriginAmount
          ),
          isRequiredReference: Boolean(
            envStore.requiredProcessEmissionReference
          ),
        }
      case ActTypeEnum.FugitiveEmission:
        return {
          isRequiredEquiementInfo: Boolean(
            envStore.requiredVaporizeEmissionEquiementInfo
          ),
          isRequiredFirm: Boolean(envStore.requiredVaporizeEmissionFirm),
          isRequiredNtdAmount: Boolean(
            envStore.requiredVaporizeEmissionNtdAmount
          ),
          isRequiredOriginAmount: Boolean(
            envStore.requiredVaporizeEmissionOriginAmount
          ),
          isRequiredReference: Boolean(
            envStore.requiredVaporizeEmissionReference
          ),
        }
      case ActTypeEnum.PowerInput:
        return {
          isRequiredEquiementInfo: Boolean(
            envStore.requiredInputPowerEquiementInfo
          ),
          isRequiredFirm: Boolean(envStore.requiredInputPowerFirm),
          isRequiredNtdAmount: Boolean(envStore.requiredInputPowerNtdAmount),
          isRequiredOriginAmount: Boolean(
            envStore.requiredInputPowerOriginAmount
          ),
          isRequiredReference: Boolean(envStore.requiredInputPowerReference),
        }
      case ActTypeEnum.SteamInput:
        return {
          isRequiredEquiementInfo: Boolean(
            envStore.requiredInputSteamEquiementInfo
          ),
          isRequiredFirm: Boolean(envStore.requiredInputSteamFirm),
          isRequiredNtdAmount: Boolean(envStore.requiredInputSteamNtdAmount),
          isRequiredOriginAmount: Boolean(
            envStore.requiredInputSteamOriginAmount
          ),
          isRequiredReference: Boolean(envStore.requiredInputSteamReference),
        }
      case ActTypeEnum.Shipment:
        return {
          isRequiredAddressFrom: Boolean(
            envStore.requiredGoodsTransAddressFrom
          ),
          isRequiredAddressTo: Boolean(envStore.requiredGoodsTransAddressTo),
          isRequiredFirm: Boolean(envStore.requiredGoodsTransFirm),
          isRequiredNtdAmount: Boolean(envStore.requiredGoodsTransNtdAmount),
          isRequiredOriginAmount: Boolean(
            envStore.requiredGoodsTransOriginAmount
          ),
          isRequiredTransContent: Boolean(
            envStore.requiredGoodsTransTransContent
          ),
        }
      case ActTypeEnum.PurchaseGoods:
        return {
          isRequiredNtdAmount: Boolean(envStore.requiredPurchaseGoodsNtdAmount),
          isRequiredOriginAmount: Boolean(
            envStore.requiredPurchaseGoodsOriginAmount
          ),
        }
      case ActTypeEnum.Assets:
        return {
          isRequiredSN: Boolean(envStore.requiredAssetMgmtSN),
        }
      case ActTypeEnum.WasteDisposal:
        return {
          isRequiredFirm: Boolean(envStore.requiredScrapFirm),
          isRequiredReference: Boolean(envStore.requiredScrapReference),
        }
      case ActTypeEnum.WaterUsage:
        return {
          isRequiredFirm: Boolean(envStore.requiredWaterUsageFirm),
          isRequiredNtdAmount: Boolean(envStore.requiredWaterUsageNtdAmount),
          isRequiredOriginAmount: Boolean(
            envStore.requiredWaterUsageOriginAmount
          ),
          isRequiredReference: Boolean(envStore.requiredWaterUsageReference),
        }
      default:
        return undefined
    }
  }, [atype])

  const requiredGoodsInfo = React.useCallback(() => {
    return {
      isRequiredFirm: envStore.requiredGoodInfoFirm,
      isRequiredAnnounceYear: envStore.requiredGoodInfoAnnounceYear,
    }
  }, [])

  const requiredEmployeeCommute = React.useCallback(
    (type: 'commute' | 'trip') => {
      switch (type) {
        case 'commute':
          return {
            isRequiredAddressFrom: envStore.requiredEmployeeCommuteAddressFrom,
            isRequiredAddressTo: envStore.requiredEmployeeCommuteAddressTo,
          }
        case 'trip':
          return {
            isRequiredAddressFrom: envStore.requiredEmployeeTravelAddressFrom,
            isRequiredAddressTo: envStore.requiredEmployeeTravelAddressTo,
          }
      }
    },
    []
  )

  return {
    required,
    requiredGoodsInfo,
    requiredEmployeeCommute,
  }
}

export const useOrganizationDisplayLabel = () => {
  const { t } = useTranslation()
  const str = t('common:organizationDisplayLabel')
  const hasValue = str !== 'organizationDisplayLabel'

  return hasValue
    ? t('common:organizationDisplayLabel')
    : envStore.organizationDisplayLabel
}

/**
 * 之後名稱設定在 locales, 不設定在 runtime
 */
export const useDisplayLabel = () => {
  const { t } = useTranslation()

  const fullNameLabelForBranch = React.useMemo(() => {
    const str = t('common:fullNameLabelForBranch')
    const hasValue = str !== 'fullNameLabelForBranch'

    return hasValue
      ? t('common:fullNameLabelForBranch')
      : envStore.fullNameLabelForBranch
  }, [])

  const shortNameLabelForBranch = React.useMemo(() => {
    const str = t('common:shortNameLabelForBranch')
    const hasValue = str !== 'shortNameLabelForBranch'

    return hasValue
      ? t('common:shortNameLabelForBranch')
      : envStore.shortNameLabelForBranch
  }, [])

  const shortNameLabelForBranchList = React.useMemo(() => {
    const str = t('common:shortNameLabelForBranchList')
    const hasValue = str !== 'shortNameLabelForBranchList'

    return hasValue
      ? t('common:shortNameLabelForBranchList')
      : envStore.shortNameLabelForBranchList
  }, [])

  const baseQuantityLabel = React.useMemo(() => {
    const str = t('common:baseQuantity')
    const hasValue = str !== 'baseQuantity'

    return hasValue ? t('common:baseQuantity') : envStore.baseQuantity
  }, [])

  return {
    fullNameLabelForBranch,
    shortNameLabelForBranch,
    shortNameLabelForBranchList,
    baseQuantityLabel,
  }
}

export const useHiddenNoIndependDocuments = () => {
  return envStore.hiddenNoIndependDocuments
}

export const useHiddenOriginAmount = () => {
  return envStore.hiddenOriginAmount
}

export const useHiddenNTDAmount = () => {
  return envStore.hiddenNTDAmount
}

export const useHiddenFieldsForGoodsMgmt = () => {
  const isHidden = React.useCallback((fname: string) => {
    if (!envStore.hiddenFieldsForGoodsMgmt) {
      return false
    }
    const fNames = envStore.hiddenFieldsForGoodsMgmt

    if (isNil(fNames)) {
      return false
    }

    return includes(fname, fNames.split(','))
  }, [])

  return {
    isHidden,
  }
}

export const useHiddenFieldsForAssetsMgmt = () => {
  const isHidden = React.useCallback((fname: string) => {
    if (!envStore.hiddenFieldsForAssetsMgmt) {
      return false
    }
    const fNames = envStore.hiddenFieldsForAssetsMgmt

    if (isNil(fNames)) {
      return false
    }

    return includes(fname, fNames.split(','))
  }, [])

  return {
    isHidden,
  }
}

export const useRequiredFormText = () => {
  return { formTypeId: Number(envStore.requiredFormText) }
}
