export const menuIcons: any = {
  類別1: 'IconCate1',
  類別2: 'IconCate2',
  類別3: 'IconCate3',
  類別4: 'IconCate4',
  類別5: 'IconCate5',
  類別6: 'IconCate6',
  清單維護: 'IconMaintain',
  歷史紀錄: 'IconHistory',
  重大性評估: 'IconEvaluate',
  清冊製作: 'IconReport',
  統計圖表: 'IconStatic',
  系統設定: 'IconSystem',
  公司清單: 'IconCompanyList',
  公司管理: 'IconCompany',
  綠電憑證: 'IconGreenPower',
  告警設定: 'IconAlert',
  係數設定: 'IconDatabase',
  帳號管理: 'IconUser',
  帳戶管理: 'IconUser',
  簽核: 'IconApproval',
  簽核設定: 'IconApproval',
}
