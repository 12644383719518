import moment from 'moment-timezone'
const clientTz = moment.tz.guess()

/**
 * @desc antd Datepicker moment 轉成 預設時區 tz
 * @param str
 * @returns millionsec
 */
export const convertTimezoneMS = (str: moment.Moment) => {
  // from antd moment
  if (!str.tz) {
    const localDate = moment(str).tz(clientTz).format('YYYY-MM-DD')
    const twDate = moment(`${localDate} 00:00:00`).tz('Asia/Taipei')
    return twDate.valueOf()
  } else {
    // 已經是預設時區
    if (str.tz() === 'Asia/Taipei') {
      return str.valueOf()
    }

    const localDate = moment(str).tz(clientTz).format('YYYY-MM-DD')
    const twDate = moment(`${localDate} 00:00:00`).tz('Asia/Taipei')
    return twDate.valueOf()
  }
}
/**
 * api 轉 moment
 */
export const toMomentRangeDate = (
  str: number[] | string[] | undefined[],
  fname: string
) => {
  const [startAt, endAt] = str
  return {
    [fname]: [
      startAt ? moment(startAt) : undefined,
      endAt ? moment(endAt) : undefined,
    ],
  }
}

export const toDateMS = (str?: moment.Moment) => {
  if (!str) {
    return undefined
  }
  const ms = convertTimezoneMS(str)
  return ms
}
