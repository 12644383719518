import { Spin } from 'antd'

type Props = {
  loading: boolean
  className?: string
  children?: React.ReactNode
}

const ContainerLoading = ({ loading, className, ...props }: Props) => {
  return (
    <div
      className={className}
      style={{ background: '#fff', borderRadius: '16px' }}>
      <Spin tip="Loading..." size="large" spinning={loading}>
        {props.children}
      </Spin>
    </div>
  )
}

export default ContainerLoading
